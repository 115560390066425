<template>
  <div class="index-root">
    <div class="index-body dp-flex">
      <div class="index-left">
        <img src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_index_avatat.png"
             width="56"
             height="56"
        />
        <div class="il-ope">
          <div class="il-ope-button display-flex-center" @click="goLogin">{{ this.token ? '退出' : '登录' }}</div>
          <div style="width: 5px"></div>
          <div class="il-ope-button display-flex-center" @click="goRegister"
               :style="{'color':enterpriseState==='CERTIFIED'?'#2bcde0 ':'#ff9f38 '}">
            {{ this.token ? getEnterpriseState(this.enterpriseState) : '注册' }}
          </div>
        </div>
        <div @click="setShowList(1)" :class="['il-select',selectItem===1 ? 'il-select-check' : '',]">
          <Icon v-if="selectItem===1" type="md-send" class="il-select-icon"/>
          热门推荐
        </div>
        <div @click="setShowList(2)" :class="['il-select',selectItem===2 ? 'il-select-check' : '',]">
          <Icon v-if="selectItem===2" type="md-send" class="il-select-icon"/>
          需求大厅
        </div>
        <div @click="setShowList(3)" :class="['il-select',selectItem===3 ? 'il-select-check' : '',]">
          <Icon v-if="selectItem===3" type="md-send" class="il-select-icon"/>
          政策通知
        </div>
        <div @click="setShowList(4)" :class="['il-select',selectItem===4 ? 'il-select-check' : '',]">
          <Icon v-if="selectItem===4" type="md-send" class="il-select-icon"/>
          活动通知
        </div>
        <div @click="setShowList(5)" :class="['il-select',selectItem===5 ? 'il-select-check' : '',]">
          <Icon v-if="selectItem===5" type="md-send" class="il-select-icon"/>
          新闻报道
        </div>
        <div @click="setShowList(6)" :class="['il-select',selectItem===6 ? 'il-select-check' : '',]">
          <Icon v-if="selectItem===6" type="md-send" class="il-select-icon"/>
          知识百科
        </div>
      </div>
      <div class="index-center dp-flex flex-column">
        <Carousel v-model="value1" radius-dot loop v-if="selectItem===1">
          <CarouselItem v-for="(item,index) in indexList.config" :key="item.order + index">
            <a :href="item.target" target="_blank"><img :src="item.pic" class="ic-img"/></a>
          </CarouselItem>
        </Carousel>
        <div class="ic-ope" v-if="selectItem===1">
          <div class="ic-ope-body" @click="goSellerCenter">
            <div class="ic-ope-body-img b1"></div>
            <div class="ic-ope-body-text display-flex-center">发布成果</div>
          </div>
          <div class="ic-ope-body" @click="goEnterPriseCenterB">
            <div class="ic-ope-body-img b2"></div>
            <div class="ic-ope-body-text display-flex-center">发布需求</div>
          </div>
          <div class="ic-ope-body" @click="goSellerCenter">
            <div class="ic-ope-body-img b3"></div>
            <div class="ic-ope-body-text display-flex-center">发布服务</div>
          </div>
          <div class="ic-ope-body" @click="goScienceService">
            <div class="ic-ope-body-img b4"></div>
            <div class="ic-ope-body-text display-flex-center">科技服务</div>
          </div>
          <div class="ic-ope-body" @click="goQixiao">
            <div class="ic-ope-body-img b5"></div>
            <div class="ic-ope-body-text display-flex-center">需求匹配</div>
          </div>
          <div class="ic-ope-body" @click="goExamination">
            <div class="ic-ope-body-img b6"></div>
            <div class="ic-ope-body-text display-flex-center">企业体检</div>
          </div>
        </div>

        <!--        需求列表的类型选择-->
        <div class="require-type" v-if="selectItem===2">
          <span v-for="(item,index) in demandTypeList" :key="index"
                :class="['require-type-text',selectRequireType === item.requirementType ? 'rt-text-select' : '',]"
                @click="setSelectRequire(item.requirementType)"
          >{{ item.requirementTypeName }}</span>
          <span class="require-type-text" @click="goCommonDemandHall()">更多</span>
        </div>
        <!--        需求列表-->
        <List item-layout="vertical" :split="false" v-if="selectItem===2"
              :loading="listLoading">
          <ListItem v-for="(item,index) in showList" :key="index">
            <router-link
                class="ic-list"
                :to="{ path: '/home/demandDetail', query: { id: item.id } }"
                target="_blank">
              <div class="dp-flex flex-align-center">
                <div :class="getDemandTypeClass">{{ item.requirementTypeName }}</div>
                <div class="ic-list-title ellipse-1">{{ item.title }}</div>
                <div class="ic-list-time">{{ item.updatedTime }}</div>
              </div>
              <div class="ic-list-content ellipse-2">
                {{ item.content }}
              </div>
              <div class="ic-list-money">
                <span style="color:rgba(255, 114, 0, 1);">协商确定</span>
                <span>预算金额：</span>
              </div>
            </router-link>
          </ListItem>
        </List>
        <!--        热门推荐列表以及活动通知列表以及知识百科列表-->
        <List item-layout="vertical" :split="false" v-if="selectItem===1||selectItem===4||selectItem===6"
              :loading="listLoading">
          <ListItem v-for="(item,index) in showList" :key="index">
            <router-link
                class="hot-recommend"
                :to="{ path: '/home/knowledgeDetail', query: { id: item.id } }"
                target="_blank"
            >
              <div class="hr-title ellipse-1">{{ item.title }}</div>
              <div class="hr-time">{{ item.updatedTime }}
              </div>
              <div class="hr-introduction ellipse-2">{{ item.introduction }}</div>
              <div class="hr-label">标签：
                <span class="hr-label-tag" v-for="(item,ind) in item.label.split(';')" :key="ind">{{ item }}</span>
              </div>
            </router-link>
          </ListItem>
        </List>
        <div class="require-type" v-if="selectItem===3">
          <Select placeholder="请选择省" :disabled="disableSelectArea" v-model="selectProvinceCode" clearable
                  style="width: 30%"
                  @on-clear="onClearSelectProvince"
                  @on-change="onSelectProvince">
            <Option v-for="item in provinceList" :value="item.provinceCode" :key="item.provinceCode">
              {{ item.provinceName }}
            </Option>
          </Select>
          <Select placeholder="请选择市" :disabled="disableSelectArea" v-model="selectCityCode" clearable
                  style="width: 30%"
                  @on-clear="onClearSelectCity"
                  @on-change="onSelectCity">
            <Option v-for="item in cityList" :value="item.cityCode" :key="item.cityCode">
              {{ item.cityName }}
            </Option>
          </Select>
          <Select placeholder="请选择区/县" :disabled="disableSelectArea" v-model="selectAreaCode" clearable
                  style="width: 30%"
                  @on-clear="onClearSelectArea"
                  @on-change="onSelectArea">
            <Option v-for="item in areaList" :value="item.areaCode" :key="item.areaCode">
              {{ item.areaName }}
            </Option>
          </Select>
        </div>
        <!--        政策通知列表-->
        <List item-layout="vertical" :split="false" v-if="selectItem===3" :loading="listLoading">
          <ListItem v-for="(item,index) in showList" :key="index">
            <router-link
                class="hot-recommend"
                :to="{path: 'sciencePolicyDetail',query:{'id':item.id}}"
                target="_blank"
                style="height: 128px"
            >
              <div class="hr-title ellipse-1">{{ item.title }}</div>
              <div class="hr-time">{{ item.updatedTime }}</div>
              <div class="hr-time">地区分类：{{ item.provinceCode }}{{ item.cityCode }}{{ item.areaCode }}</div>
            </router-link>
          </ListItem>
        </List>
        <!--        新闻报道列表-->
        <List item-layout="vertical" :split="false" v-if="selectItem===5" :loading="listLoading">
          <ListItem v-for="(item,index) in showList" :key="index">
            <router-link
                class="news-report"
                :to="{path: 'mediaReportDetail',query:{'id':item.id}}"
                target="_blank">
              <img :src="item.imageUrl" class="nr-img"/>
              <div class="nr-des">
                <div class="hr-title ellipse-2">{{ item.title }}</div>
                <div class="hr-time">{{ item.updatedTime }}</div>
                <div class="hr-label">标签：
                  <span class="hr-label-tag" v-for="(citem,cindex) in item.label.split(';')" :key="cindex">{{ citem }}</span>
                </div>
              </div>
            </router-link>
          </ListItem>
        </List>
        <!--        分页-->
        <div class="index-page display-flex-center" v-if="selectItem!==2">
          <Page
              :current="pageNum"
              :total="pageTotal"
              :page-size="6"
              show-elevator
              @on-change="changePage"
          />
        </div>

        <div @click="goTypeDemandHall" class="index-require-button display-flex-center" v-if="selectItem===2">查看更多</div>
      </div>

      <div class="index-right">
        <div class="ir-two">
          <div class="ir-one-title dp-flex flex-align-center">
            <img width="20" height="20"
                 src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_buttong.png"/>
            <span style="margin-left: 7px">企业入口</span>
          </div>
          <div class="ir-two-measure">
              <div class="cultivate-img" @click="goEnterPriseCultivate"></div>
          </div>
          <div class="ir-two-ope">
            <div class="ir-two-ope-click img1" @click="goEnterPriseCenterA"></div>
            <div class="ir-two-ope-click img2" @click="goEnterPriseCenterB"></div>
            <div class="ir-two-ope-click img3" @click="goEnterPriseCenterC"></div>
            <div class="ir-two-ope-click img4" @click="goEnterPriseCenterD"></div>
          </div>
        </div>

        <div class="ir-one">
          <div class="ir-one-title dp-flex flex-align-center">
            <img width="20" height="20"
                 src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_buttonf.png"/>
            <span style="margin-left: 7px">机构入口</span>
          </div>
          <div class="ir-one-imgA" @click="goSellerCenter"/>
          <div class="ir-one-imgB" @click="goSellerCenter"/>
        </div>

        <div class="ir-three">
          <div class="ir-one-title dp-flex flex-align-center">
            <img width="20" height="20"
                 src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_buttonl.png"/>
            <span style="margin-left: 7px">公告</span>
          </div>
          <div class="ir-three-text">
            今年上半年数据显示，知识产权保护环境持续向好
          </div>
          <div class="ir-three-text">
            江苏省近两千项专利成果即将上线竞拍
          </div>
          <div class="ir-three-text">
            全国首单碳中和信用保护凭证江苏发出
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import IndexCom from '@/components/IndexCom'
import {
  getIndexFloorDetail,
  getIndexRequirementData,
} from "../../plugins/api/indexApi";
import {getKnowledgeListById} from "../../plugins/api/encyclopediaKnowledgeApi";
import {mapGetters, mapMutations} from "vuex";
import {getSciencePolicyList} from "../../plugins/api/sciencePolicyApi";
import {getMediaReportList} from "../../plugins/api/mediaReportApi";
import {getDemandTypeList, getDemandListByType} from "../../plugins/api/demandApi";
import {getAreaListByCityCode, getCityListByProvinceCode, getProvinceList} from "../../plugins/api/regionApi";
import { hostname,domain } from "@/config";

export default {
  name: "ComponentIndex",
  componentList: [],
  components: {
    IndexCom,
    'demandInformation': () => import('@/components/index/homePageComponent/demandAndInformation'),//需求大厅头条资讯
    'enterpriseSchoolCase': () => import('@/components/index/homePageComponent/enterpriseSchoolCase'),//企效通案例
    'hotPatent': () => import('@/components/index/homePageComponent/hotPatent'),//热门专利推荐
    'racePolicy': () => import('@/components/index/homePageComponent/raceAndPolicy'),//科技竞赛科技政策
    'hotService': () => import('@/components/index/homePageComponent/hotService'),//热门服务推荐
    'thirdShop': () => import('@/components/index/homePageComponent/thirdShop'),
    'expertRecommend': () => import('@/components/index/homePageComponent/expertRecommend'),//科研专家推荐
    'testAndNews': () => import('@/components/index/homePageComponent/testAndNews'),//企业体检科技新闻
    'universityStore': () => import('@/components/index/homePageComponent/universityStore'),//高校旗舰店
    'wikiAndReport': () => import('@/components/index/homePageComponent/wikiAndReport'),//知产百科媒体报道,
    'singleAD': () => import('@/components/index/homePageComponent/singleAD') // 单广告楼层
  },
  computed: {
    ...mapGetters({
      token: "userStore/token",
      userName: "userStore/userName",
      userId: "userStore/userId",
      enterpriseState: "userStore/enterpriseState"
    }),
    getDemandTypeClass() {
      switch (this.selectRequireType) {
        case "PROJECT_DECLARATION":
          return 'ic-list-tagA'
        case "PATENT_APPLICATION":
          return 'ic-list-tagB'
        case "INTELLECTUAL_PROPERTY_RIGHT":
          return 'ic-list-tagC'
        case "LEGAL_AID":
          return 'ic-list-tagD'
        case "ANALYSIS_OF_PATENT_INFORMATION":
          return 'ic-list-tagE'
        case "EVALUATION_OF_PATENT_VALUE":
          return 'ic-list-tagF'
        case "TECHNICAL_AUTOMATIC_MATCHING":
          return 'ic-list-tagG'
        case "OTHER_REQUIREMENTS":
          return 'ic-list-tagH'
      }
    }
  },
  data() {
    return {
      value1: 0,// 轮播图数据
      indexList: [],//首页数据
      showList: [],//展示的列表
      pageTotal: 0,//分页一共多少条数据
      pageNum: 1,//分页当前页
      selectItem: 1,//当前选择的展示列表1.热门推荐2.需求大厅3.政策通知4.活动通知5.新闻报道6.知识百科
      listLoading: true,//列表是否加载中
      demandTypeList: [],//需求类型列表
      selectRequireType: '',//当前选择的需求类型
      provinceList: [],//省份列表
      cityList: [],//城市列表
      areaList: [],//区列表
      selectProvinceCode: -1,//选择的省份编码
      selectCityCode: -1,//选择的城市编码
      selectAreaCode: -1,//选择的区编码
      disableSelectArea: true,//是否禁用选择地址
    }
  },
  mounted() {
    this.getIndexShowDetail();
    this.getBaiKeList(1);
    this.getProvinceList();
  },
  methods: {
    ...mapMutations({
      setUserToken: "userStore/setUserToken",
      setUserName: "userStore/setUserName",
      setUserAccountType: "userStore/setUserAccountType",
      setUserMbrMemberId: "userStore/setUserMbrMemberId",
      setUserId: "userStore/setUserId",
      setUserEnterpriseState: "userStore/setUserEnterpriseState"
    }),
    //获取首页展示数据
    async getIndexShowDetail() {
      const json = await getIndexFloorDetail();
      if (json && json.code === 0) {
        this.indexList = json.result;
      }
    },
    //登录
    goLogin() {
      if (this.token) {
        this.logOut()
        return
      }
      this.$router.push({
        path: `/login`,
        params: {
          type: 1
        }
      });
    },
    //退出登录
    logOut() {
      this.setUserToken("");
      this.setUserName("");
      this.setUserAccountType(-1);
      this.setUserMbrMemberId("");
      this.setUserId("");
      this.setUserEnterpriseState("");
      this.$cookies.set("token", "", null, null, domain);
    },
    //注册
    goRegister() {
      if (this.token) {
        if (this.enterpriseState === 'UNCERTIFIED') {
          this.$router.push({
            path: '/home/EnterpriseCertification'
          });
        }
        return
      }
      this.$router.push({
        name: `login`,
        params: {
          type: 4
        }
      });
    },
    // 获取企业认证状态
    getEnterpriseState(state) {
      switch (state) {
        case 'CERTIFIED':
          return '已认证'
        case 'EXAMINING':
          return '待审核';
        case 'UNCERTIFIED':
          return '未认证';
      }
    },
    //跳转卖家中心
    goSellerCenter() {
      window.open("http://seller.lygstrim.com/broker-dist/user/login");
    },
    //校验是否登录
    checkIsLogin() {
      if (!this.token) {
        this.$router.push({
          path: `/login`,
          params: {
            type: 1
          }
        });
        return
      }
      if (this.enterpriseState === 'UNCERTIFIED') {
        this.$router.push({
          path: '/home/EnterpriseCertification'
        });
      }
    },
    goEnterPriseCultivate() {
      this.checkIsLogin()
      this.enterpriseState === 'CERTIFIED' && this.token && this.$router.push({
        path: "/enterprise/enterprise-cultivate",
      });
    },
    goEnterPriseCenterA() {
      this.checkIsLogin()
      this.enterpriseState === 'CERTIFIED' && this.token && this.$router.push({
        path: "/enterprise/enterprise-management-center",
        query: {
          id: 0
        }
      });
    },
    goEnterPriseCenterB() {
      this.checkIsLogin()
      this.enterpriseState === 'CERTIFIED' && this.token && this.$router.push({
        path: "/enterprise/enterprise-school-connection",
      });
    },
    goEnterPriseCenterC() {
      this.checkIsLogin()
      this.enterpriseState === 'CERTIFIED' && this.token && this.$router.push({
        path: "/enterprise/patent-trusteeship",
        query: {
          id: 0
        }
      });
    },
    goEnterPriseCenterD() {
      this.checkIsLogin()
      this.enterpriseState === 'CERTIFIED' && this.token && this.$router.push({
        path: "/enterprise/enterprise-physical-examination",
        query: {
          id: 0
        }
      });
    },
    //跳转科技服务
    goScienceService() {
      this.$router.push({
        path: "/home/science-service",
      });
    },
    //跳转企效通
    goQixiao() {
      this.$router.push({
        path: "/home/qixiao",
      });
    },
    //跳转企业体检
    goExamination() {
      this.$router.push({
        path: "/home/examination",
      });
    },
    //设置展示的列表
    setShowList(select) {
      if (this.selectItem === select) {
        return
      }
      this.selectItem = select;
      this.pageNum = 1;
      switch (select) {
        case 1:
          this.getBaiKeList(1);
          break
        case 2:
          this.getRequireType();
          break
        case 3:
          this.getPolicyNotice();
          break
        case 4:
          this.getBaiKeList(3);
          break
        case 5:
          this.getNewsReport()
          break
        case 6:
          this.getBaiKeList(2);
          break
      }
    },
    //监听分页变化
    changePage(index) {
      this.pageNum = index;
      switch (this.selectItem) {
        case 1:
          this.getBaiKeList(1);
          break
        case 2:

          break
        case 3:
          this.getPolicyNotice();
          break
        case 4:
          this.getBaiKeList(3);
          break
        case 5:
          this.getNewsReport()
          break
        case 6:
          this.getBaiKeList(2);
          break
      }
    },
    //获取热门推荐 1.热门推荐3.活动通知2.知识百科
    async getBaiKeList(type) {
      this.listLoading = true;
      let params = {
        pageSize: 6,
        pageNum: this.pageNum,
        articleTypeId: type,
      }
      const json = await getKnowledgeListById(params).catch(() => this.listLoading = false);
      if (json && json.code === 0) {
        if (json.result && json.result.list) {
          this.showList = json.result.list
          this.pageTotal = json.result.total
        }
      }
      this.listLoading = false
    },
    //获取需求分类
    async getRequireType() {
      const json = await getDemandTypeList();
      if (json && json.code === 0) {
        this.demandTypeList = json.result;
        this.selectRequireType = json.result[0].requirementType;
        this.getRequireList();
      }
    },
    //获取需求列表
    async getRequireList() {
      this.listLoading = true;
      let params = {
        pageSize: 6,
        pageNum: this.pageNum,
        requirementType: this.selectRequireType
      }
      const json = await getDemandListByType(params).catch(() => {
        this.listLoading = false
      });
      if (json && json.code === 0) {
        this.showList = json.result.list;
        this.pageTotal = json.result.total;
      }
      this.listLoading = false
    },
    //设置需求
    setSelectRequire(type) {
      this.selectRequireType = type
      this.getRequireList();
    },
    //获取政策通知
    async getPolicyNotice() {
      this.disableSelectArea = true;
      this.listLoading = true;
      this.showList = [];
      const {pageNum, selectProvinceCode, selectCityCode, selectAreaCode} = this;
      const params = {
        pageSize: 10,
        pageNum: pageNum,
        provinceCode: selectProvinceCode,
        cityCode: selectCityCode,
        areaCode: selectAreaCode,
      }
      const json = await getSciencePolicyList(params).catch(() => {
        this.disableSelectArea = false;
        this.listLoading = false
      });
      if (json && json.code === 0) {
        if (json.result) {
          this.showList = json.result.list;
          this.pageTotal = json.result.total;
        }
      }
      this.disableSelectArea = false;
      this.listLoading = false
    },
    // 获取新闻报道
    async getNewsReport() {
      this.listLoading = true;
      const params = {
        pageSize: 6,
        pageNum: this.pageNum
      }
      const json = await getMediaReportList(params).catch(() => this.listLoading = false);
      if (json && json.code === 0) {
        if (json.result && json.result.list && json.result.list.length > 0) {
          this.showList = json.result.list;
          this.pageTotal = json.result.total;
        }
      }
      this.listLoading = false
    },
    //跳转需求大厅
    goCommonDemandHall() {
      this.$router.push({
        path: "/home/DemandHall",
      })
    },
    //根据类型跳转需求大厅
    goTypeDemandHall() {
      this.$router.push({
        path: "/home/DemandHall",
        query: {type: this.selectRequireType}
      })
    },
    //获取省份列表
    async getProvinceList() {
      const json = await getProvinceList();
      if (json && json.code === 0) {
        if (json.result && json.result.length > 0) {
          this.provinceList = json.result
        }
      }
    },

    //获取城市列表
    async getCityList(provinceCode) {
      const params = {
        provinceCode: provinceCode
      }
      const json = await getCityListByProvinceCode(params);
      if (json && json.code === 0) {
        if (json.result && json.result.length > 0) {
          this.cityList = json.result
        }

      }
    },

    //获取区列表
    async getAreaList(cityCode) {
      const params = {
        cityCode: cityCode
      }
      const json = await getAreaListByCityCode(params)
      if (json && json.code === 0) {
        if (json.result && json.result.length > 0) {
          this.areaList = json.result
        }

      }

    },
    //清除选择省份时触发
    onClearSelectProvince() {
      this.selectProvinceCode = -1;
      this.selectCityCode = -1;
      this.selectAreaCode = -1;
      this.cityList = [];
      this.areaList = [];
      this.getPolicyNotice();
    },

    //选择省份时触发
    onSelectProvince(value) {
      this.cityList = [];
      this.areaList = [];
      this.selectCityCode = -1;
      this.selectAreaCode = -1;
      if (!value) {
        return
      }
      this.getCityList(value);
      this.getPolicyNotice();
    },

    //选择城市时触发
    onSelectCity(value) {
      this.areaList = [];
      this.selectAreaCode = -1;
      if (!value) {
        return
      }
      this.getAreaList(value);
      this.getPolicyNotice();
    },

    //清除选择城市时触发
    onClearSelectCity() {
      this.selectCityCode = -1;
      this.selectAreaCode = -1;
      this.areaList = [];
      this.getPolicyNotice();
    },

    //选择区时触发
    onSelectArea(value) {
      if (!value) {
        return;
      }
      this.getPolicyNotice();
    },
    //清除区时触发
    onClearSelectArea() {
      this.selectAreaCode = -1;
      this.getPolicyNotice();
    },

  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-list-item {
  padding: 0;
}

.index-root {
  /*background-image: url("https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_bannerb.png");*/
  background-image: url("~@/assets/image/index/index-bg.jpg");
  background-size: 100% 900px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-y:202px;
}

.index-body {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.index-left {
  display: flex;
  flex-direction: column;
  margin-top: 31px;
  align-items: center;
  width: 104px;
  position: fixed;
  top: 212px;
}

.il-ope {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.il-ope-button {
  width: 50px;
  height: 22px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}

.il-select {
  display: flex;
  flex-direction: row;
  width: 104px;
  height: 30px;
  background: rgba(13, 51, 108, 0.25);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 10px;
  cursor: pointer;
}

.il-select-icon {
  margin-right: 8px;
}

.il-select-check {
  /*background: linear-gradient(90deg, #2ACCDF 0%, #2ACCDF 100%);*/
  background: #FC8A27;
}


.index-center {
  margin-top: 31px;
  width: 760px;
  margin-left: 124px;
}

.ic-img {
  width: 100%;
  height: 324px;
  border-radius: 10px;
}


.ic-ope {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 158px;
}

.ic-ope-body {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.ic-ope-body-img {
  width: 84px;
  height: 84px;
}

.b1{
  background-image: url("~@/assets/image/index/b1.png");
  background-size: 100% 100%;
}
.b1:hover{
  background-image: url("~@/assets/image/index/b1-hover.png");
  background-size: 100% 100%;
}
.b2{
  background-image: url("~@/assets/image/index/b2.png");
  background-size: 100% 100%;
}
.b2:hover{
  background-image: url("~@/assets/image/index/b2-hover.png");
  background-size: 100% 100%;
}
.b3{
  background-image: url("~@/assets/image/index/b3.png");
  background-size: 100% 100%;
}
.b3:hover{
  background-image: url("~@/assets/image/index/b3-hover.png");
  background-size: 100% 100%;
}
.b4{
  background-image: url("~@/assets/image/index/b4.png");
  background-size: 100% 100%;
}
.b4:hover{
  background-image: url("~@/assets/image/index/b4-hover.png");
  background-size: 100% 100%;
}
.b5{
  background-image: url("~@/assets/image/index/b5.png");
  background-size: 100% 100%;
}
.b5:hover{
  background-image: url("~@/assets/image/index/b5-hover.png");
  background-size: 100% 100%;
}
.b6{
  background-image: url("~@/assets/image/index/b6.png");
  background-size: 100% 100%;
}
.b6:hover{
  background-image: url("~@/assets/image/index/b6-hover.png");
  background-size: 100% 100%;
}
.ic-ope-body-text {
  font-size: 14px;
  font-weight: 400;
  color: #4B9BF3;
  margin-top: 10px;
}

.ic-list {
  background: #FFFFFF;
  box-shadow: 0 4px 5px 0 rgba(136, 176, 240, 0.15);
  border-radius: 10px;
  padding: 15px 23px 15px 26px;
  margin-bottom: 15px;
  height: 140px;
  display: flex;
  flex-direction: column;
}

.ic-list-tagA {
  padding: 4px 7px;
  background: #CEDFF8;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #1062DF;
}

.ic-list-tagB {
  padding: 4px 7px;
  background: rgba(222, 206, 250, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(138, 74, 245, 1);
}

.ic-list-tagC {
  padding: 4px 7px;
  background: rgba(186, 238, 232, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(15, 178, 161, 1);
}

.ic-list-tagD {
  padding: 4px 7px;
  background: rgba(253, 221, 212, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(244, 107, 70, 1);
}

.ic-list-tagE {
  padding: 4px 7px;
  background: rgba(206, 250, 220, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(26, 181, 75, 1);
}

.ic-list-tagF {
  padding: 4px 7px;
  background: rgba(212, 215, 246, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(87, 98, 241, 1);
}

.ic-list-tagG {
  padding: 4px 7px;
  background: rgba(245, 242, 210, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(169, 159, 50, 1);
}

.ic-list-tagH {
  padding: 4px 7px;
  background: rgba(253, 219, 246, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(244, 16, 198, 1);
}

.ic-list-title {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  flex: 1;
  margin-left: 10px;
}

.ic-list-title:hover{
  color: #4B9BF3;
}

.ic-list-time {
  font-size: 14px;
  flex-shrink: 0;
  font-weight: 400;
  color: #859BBD;
  margin-left: 15px;
}

.ic-list-content {
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
  height: 40px;
  color: #666666 !important;
}

.ic-list-money {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 7px;
  font-size: 15px;
  font-weight: 400;
  color: #859BBD;
}

.index-right {
  width: 300px;
  margin-top: 31px;
}

.ir-one {
  margin-top: 15px;
  background-image: url("https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_bannerc.png");
  height: 274px;
  background-size: 100% 100%;
  padding: 15px 15px;
}

.ir-one-title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.ir-one-imgA {
  width: 282px;
  height: 92px;
  background-image: url("~@/assets/image/index/transfer.png");
  background-size: 100% 100%;
  margin-top: 15px;
  cursor: pointer;
}
.ir-one-imgA:hover{
  background-image: url("~@/assets/image/index/transfer-hover.png");
}

.ir-one-imgB {
  width: 282px;
  height: 92px;
  background-image: url("~@/assets/image/index/agencies.png");
  background-size: 100% 100%;
  margin-top: 10px;
  cursor: pointer;
}
.ir-one-imgB:hover{
  background-image: url("~@/assets/image/index/agencies-hover.png");
}

.ir-two {
  height: 402px;
  background-image: url("https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_bannerf.png");
  background-size: 100% 100%;
  padding: 15px 12px;
}
.ir-two-measure{
  margin-top: 22px;
  width: 270px;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 4px 5px 0px rgba(62, 183, 233, 0.15);
  border-radius: 6px;
  cursor: pointer;
  .cultivate-img{
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/image/index/cultivate.png");
    background-size: 100% 100%;
  }
  .cultivate-img:hover{
    background-image: url("~@/assets/image/index/cultivate-hover.png");
    background-size: 100% 100%;
  }
}
.ir-two-ope {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 12px;
}

.ir-two-ope-click {
  width: 138px;
  height: 108px;
  display: flex;
  flex-direction: column;
  /*background-color: rgba(255, 255, 255, 1);*/
  /*box-shadow: 0 4px 5px 0 rgba(62, 183, 233, 0.15);*/
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  /*margin-top: 10px;*/
  cursor: pointer;
}
.img1{
  background-image: url("~@/assets/image/index/kanban.png");
  background-size: 100% 100%;
}
.img1:hover{
  background-image: url("~@/assets/image/index/kanban-hover.png");
  background-size: 100% 100%;
}
.img2{
  background-image: url("~@/assets/image/index/pipei.png");
  background-size: 100% 100%;
}
.img2:hover{
  background-image: url("~@/assets/image/index/pipei-hover.png");
  background-size: 100% 100%;
}
.img3{
  background-image: url("~@/assets/image/index/tuoguan.png");
  background-size: 100% 100%;
}
.img3:hover{
  background-image: url("~@/assets/image/index/tuoguan-hover.png");
  background-size: 100% 100%;
}
.img4{
  background-image: url("~@/assets/image/index/tijian.png");
  background-size: 100% 100%;
}
.img4:hover{
  background-image: url("~@/assets/image/index/tijian-hover.png");
  background-size: 100% 100%;
}

.ir-three {
  background-image: url("https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_bannerg.png");
  background-size: 100% 100%;
  margin-top: 15px;
  height: 158px;
  padding: 15px 15px;
}

.ir-three-text {
  font-size: 12px;
  margin-top: 15px;
  font-weight: 400;
  color: #666666;
}

.index-page {
  background: #FFFFFF;
  box-shadow: 0 4px 5px 0 rgba(136, 176, 240, 0.15);
  border-radius: 10px;
  margin-bottom: 50px;
  height: 72px;
}

.hot-recommend {
  background: #FFFFFF;
  box-shadow: 0 4px 5px 0 rgba(136, 176, 240, 0.15);
  border-radius: 10px;
  padding: 20px 23px 17px 12px;
  margin-bottom: 15px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hr-title {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  flex-shrink: 0;
}

.hr-title:hover{
  color: #4B9BF3;
}

.hr-time {
  font-size: 14px;
  font-weight: 400;
  color: #859BBD;
  flex-shrink: 0;
}

.hr-introduction {
  font-size: 14px;
  font-weight: 400;
  color: #666666 !important;
}

.hr-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #859BBD;
  flex-shrink: 0;
}

.hr-label-tag {
  background-color: #D7E2F2;
  border-radius: 2px;
  margin-left: 10px;
  padding: 1px 5px;
}

.news-report {
  height: 180px;
  background: #FFFFFF;
  box-shadow: 0 4px 5px 0 rgba(136, 176, 240, 0.15);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.nr-img {
  width: 200px;
  height: 140px;
  border-radius: 6px;
  flex-shrink: 0;
}

.nr-des {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
  overflow: hidden;
}

.require-type {
  height: 72px;
  background: #FFFFFF;
  box-shadow: 0 4px 5px 0 rgba(136, 176, 240, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.require-type-text {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
  height: 25px;
}

.rt-text-select {
  border-bottom: 3px solid rgba(16, 98, 223, 1);
  color: rgba(16, 98, 223, 1);
}

.index-require-button {
  width: 104px;
  height: 36px;
  background: #25B7DF;
  border-radius: 4px;
  margin: 5px auto 50px auto;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}
</style>
